<template>
    <div class="section">
        <div class="container">
            <div>
                Home
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        components: {},
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

            return {

            };
        }
    }

</script>